import React from 'react';
import { useEffect } from 'react';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import LinkList from '~2-components/LinkList/LinkList';
import LinkListKontent from '~3-data-components/LinkListKontent';
import { useStaticQuery, graphql } from 'gatsby';

const linkListEg = `import LinkList from '~2-components/LinkList/LinkList';

<LinkList
    title='' //Optional
/>
    //Content goes here
    <ul>
        <li><a href="#">etc.</a></li>
        <li><a href="#">etc.</a></li>
    </ul>
</LinkList>
`;

const SgLinkList = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allLinklists = useStaticQuery(
        graphql`
            query SGAllLinkLists {
                allKontentItemLinkList {
                    nodes {
                        system {
                            id
                            type
                        }
                        elements {
                            title {
                                value
                            }
                            links {
                                value
                            }
                        }
                    }
                }
            }
    `);

    const allLinklistContainers = allLinklists.allKontentItemLinkList.nodes;

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>LinkList</h1>
                    <h2>How to use</h2>
                    <CodeBlock
                        code={linkListEg}
                    />

                    <h2>Example</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <LinkList>
                        <ul>
                            <li><a href="#">Making a WorkCover claim</a></li>
                            <li><a href="#">Terminated payments</a></li>
                            <li><a href="#">Serious injury applications</a></li>
                            <li><a href="#">Rejected WorkCover claims</a></li>
                            <li><a href="#">Return to work obligations</a></li>
                            <li><a href="#">Financial assistance to the family of a deceased worker</a></li>
                        </ul>
                    </LinkList>
                </div>
            </section>
            <section className='sg-content'>
                <div className='container'>
                    <h2>With a Title</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <LinkList
                        title="We can also provide advice and assistance about"
                    >
                        <ul>
                            <li><a href="#">Making a WorkCover claim</a></li>
                            <li><a href="#">Terminated payments</a></li>
                            <li><a href="#">Serious injury applications</a></li>
                            <li><a href="#">Rejected WorkCover claims</a></li>
                            <li><a href="#">Return to work obligations</a></li>
                            <li><a href="#">Financial assistance to the family of a deceased worker</a></li>
                        </ul>
                    </LinkList>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent link lists</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allLinklistContainers.map((item, i) => {
                        return <LinkListKontent key={i} {...item} />;
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgLinkList;
